import React from 'react';
import ReactToPrint from 'react-to-print';


const COMPARISON_BUCKET = 'Comparative analysis (B) & Adaptation';
const buckets = ['formulation/definition',
  'assumption',
  'possible limitations Or Scope(B)',
  'Method',
  'Comparative analysis (B) & Adaptation',
  'Possible Novelty/Originality',
  'Experiment setup',
  'Research data',
  'Evaluation (how was experiment evaluated)',
  'Result/Observation',
  'Analysis & Explanation of observation',
  'speculation & expectations',
  'Future scope',
  'Special Emphasis'];

const subBucketBucketDic2 = {
  'formulation/definition': 'objective (what is the topic of investigation)',
  'assumption': 'objective (what is the topic of investigation)',
  'possible limitations Or Scope(B)': 'objective (what is the topic of investigation)',
  'Method': 'approach (how the investigation is conducted)',
  'Comparative analysis (B) & Adaptation': 'approach (how the investigation is conducted)',
  'Possible Novelty/Originality': 'approach (how the investigation is conducted)',
  'Experiment setup': 'observation & analysis (what was the outcome of the approach)',
  'Research data': 'observation & analysis (what was the outcome of the approach)',
  'Evaluation (how was experiment evaluated)': 'observation & analysis (what was the outcome of the approach)',
  'Result/Observation': 'observation & analysis (what was the outcome of the approach)',
  'Analysis & Explanation of observation': 'observation & analysis (what was the outcome of the approach)',
  'speculation & expectations': 'speculation & future direction',
  'Future scope': 'speculation & future direction',
  'Special Emphasis': 'speculation & future direction'
}
const bucketSubBucket = [{
  key: 'Objective',
  name: 'Objective',
  hasSub: true,
  subbuckets: [
    {
      key: 'formulation/definition',
      name: 'Objective Description',
    },
    {
      key: 'possible limitations Or Scope(B)',
      name: 'Scope of the Paper',
    }
  ]
},
{
  key: 'assumption',
  name: 'Assumption',
  hasSub: false
},
{
  key: 'approach',
  name: 'Approach',
  hasSub: true,
  subbuckets: [
    {
      key: 'Possible Novelty/Originality',
      name: 'Possible Originality',
    },
    {
      key: 'Method',
      name: 'Methodology',
    },
    {

      key: 'Comparative analysis (B) & Adaptation',
      name: 'Comparison and Adaptation',
    }
  ]
},
{
  key: 'observation',
  name: 'Research Outcome',
  hasSub: true,
  subbuckets: [
    { key: 'Experiment setup', name: 'Evaluation Setup' },
    { key: 'Evaluation (how was experiment evaluated)', name: 'Evaluation Method' },
    { key: 'Research data', name: 'Research Data' },
    { key: 'Result/Observation', name: 'Observation' },
    { key: 'Analysis & Explanation of observation', name: 'Analysis' },
  ]
},
{
  key: 'speculation & expectations',
  name: 'Speculation',
  hasSub: false
},
{
  key: 'Special Emphasis',
  name: 'Special Emphasis by Authors',
  hasSub: false
},
{
  key: 'Future scope',
  name: 'Future Directions',
  hasSub: false
}]

const subBucketBucketDic = {
  'formulation/definition': 'objective (what is the topic of investigation)',
  'assumption': 'objective (what is the topic of investigation)',
  'possible limitations Or Scope(B)': 'objective (what is the topic of investigation)',
  'Method': 'approach (how the investigation is conducted)',
  'Comparative analysis (B) & Adaptation': 'approach (how the investigation is conducted)',
  'Possible Novelty/Originality': 'approach (how the investigation is conducted)',
  'Experiment setup': 'Experiment setup',
  'Research data': 'Research data',
  'Evaluation (how was experiment evaluated)': 'Evaluation (how was experiment evaluated)',
  'Result/Observation': 'Result/Observation',
  'Analysis & Explanation of observation': 'Analysis & Explanation of observation',
  'speculation & expectations': 'speculation & future direction',
  'Future scope': 'speculation & future direction',
  'Special Emphasis': 'speculation & future direction'
}
const noNegationBuckets = {
  'speculation & future direction': 1
}

const Sentences = ({ sents }) => {
  return (
    <div style={{ width: '500px' }}>
      <ul>
        {sents.map((sent, index) => <li key={index}>{sent}</li>)}
      </ul>
    </div>
  )
}


const SentenceComp = ({ bucket, sent, labels, index, labelsDic, allClassifiedLabels = {} }) => {

  // const citationPresent = sent.split(/((\[[0-9]+, [0-9]+\])?)((\[[0-9]+\])?)/g);
  const citationPresent = sent.split(/\[[0-9]*\]/g);
  const labelValues = labels.map(label => labelsDic[label]);

  if (labelValues.find((item) => item === bucket)) {
    return (<li key={index}>
      <div
        highlightClassName="YourHighlightClass"
        searchWords={labels}
        autoEscape={true}
        textToHighlight={sent}
      />
      {citationPresent.length > 1 ? <div style={{ backgroundColor: 'Coral' }}> citation present</div> : null}
      {labels.map(label =>
        <div style={{ display: 'flex', padding: '20px 0px' }}>
          <div style={{ backgroundColor: 'AntiqueWhite', paddingRight: "20px" }}>
            {label}
          </div>
          <div style={{ backgroundColor: 'Aquamarine' }}>
            {labelsDic[label]}
          </div>
        </div>
      )}
      {Object.keys(allClassifiedLabels).map(label => {
        if (!labelsDic[label]) {

          return (<div style={{ display: 'flex', backgroundColor: 'MidnightBlue', color: 'white' }}>
            <div style={{ paddingRight: "20px" }}>
              {label}
            </div>
            <div style={{}}>
              {allClassifiedLabels[label]}
            </div>
          </div>);
        }
        return null;
      })}
    </li>);
  }
  return null;
}


const Token = ({ token, bgColor = 'NavajoWhite', fontColor }) => {
  return (<div style={{ display: 'flex', padding: '20px 0px', backgroundColor: bgColor, color: fontColor }}>

    <div style={{ paddingRight: "20px" }}>
      {token.text}
    </div>
    <div style={{ paddingRight: '30px' }}>
      {token[token.found]}
    </div>
    <div style={{ paddingRight: '30px' }}>
      {token.found}
    </div>
    <div style={{ paddingRight: '30px' }}>
      {token.type}
    </div>
    <div style={{}}>
      {token.winnerRule}
    </div>
  </div>)
}

const SentenceComp2 = ({ bucket, sent, index, tokensArray, scores = { scoreStrings: [] } }) => {
  const citationPresent = sent.split(/\[[0-9]*\]/g);
  if (!tokensArray) {
    return null;
  }


  return (
    <div key={index}>
      <div
        highlightClassName="YourHighlightClass"
        searchWords={[]}
        autoEscape={true}
        textToHighlight={sent}
      />
      {/* {citationPresent.length > 1 ? <div style={{ backgroundColor: 'Coral' }}> citation present</div> : null}
      {tokensArray.map(token =>
        <Token token={token} />
      )}

      <div style={{ backgroundColor: 'LightCoral', padding: '20px', fontSize: 20 }}>
        {scores.scoreVal}
      </div>
      <div style={{ backgroundColor: 'LightGray', padding: '20px 0px', fontSize: 20 }}>
        {scores.scoreStrings.map(scoreRow =>
          <div style={{ display: 'flex' }}>
            {scoreRow.map(cell => <div style={{ width: '100%' }}>{cell}</div>)}
          </div>
        )}
      </div> */}

    </div>
  );

}



const ClassifiedSentences = ({ oldresults, classifiedSentences, allClassifiedSentences = {} }) => {
  const sents = Object.keys(classifiedSentences);
  const sents2 = Object.keys(oldresults);
  return (
    <div style={{ width: '1400px', padding: '20px' }}>
      {buckets.map(bucket => {
        return <div>
          <div style={{ backgroundColor: 'grey', fontSize: '36px', padding: '20px 0px' }}>{bucket}</div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '700px' }}>
              <ol>
                {sents2.map((sent, index) => <SentenceComp bucket={bucket} index={index} sent={sent} labelsDic={oldresults[sent]} labels={Object.keys(oldresults[sent])} allClassifiedLabels={{}} />)}
              </ol>
            </div>

            <div style={{ width: '700px' }}>
              <ol>
                {sents.map((sent, index) => <SentenceComp2 bucket={bucket} index={index} sent={sent} tokensArray={classifiedSentences[sent]} allClassifiedTokens={allClassifiedSentences[sent]} />)}
              </ol>
            </div>
          </div>
        </div>
      })}
    </div>
  )
}


const ClassifiedSentences2 = ({ coreSentencesScore = {}, oldClassifiedSentences, oldAllClassifiedSentences, classifiedSentences, allClassifiedSentences = {} }) => {
  const sents = Object.keys(oldClassifiedSentences);
  return (
    <div style={{ width: '1400px', padding: '20px' }}>
      {buckets.map(bucket => {
        return <div>
          {sents.length > 0 ?
            <div style={{ backgroundColor: 'grey', fontSize: '36px', padding: '20px 0px' }}>{bucket}</div> : null}
          <div style={{ display: 'flex' }}>
            {/* <div style={{ width: '700px' }}>
              <ol>
              {sents.map((sent, index) => <SentenceComp2 bucket={bucket} index={index} sent={sent} tokensArray={oldClassifiedSentences[sent]} allClassifiedTokens={oldAllClassifiedSentences[sent]} />)}
              </ol>
            </div> */}

            <div style={{ width: '1400px' }}>
              <ol>
                {sents.map((sent, index) =>
                  <li key={index}>
                    <div style={{ display: 'flex' }}>
                      {/* <div style={{ width: '100%' }}>
                      <SentenceComp2 bucket={bucket} index={index} sent={sent} tokensArray={oldClassifiedSentences[sent]} allClassifiedTokens={oldAllClassifiedSentences[sent]} />
                      </div> */}
                      <div style={{ width: '100%' }}>
                        <SentenceComp2 bucket={bucket} index={index} sent={sent} tokensArray={classifiedSentences[sent]} allClassifiedTokens={allClassifiedSentences[sent]} scores={coreSentencesScore[sent]} />
                      </div>
                    </div>
                  </li>
                )}
              </ol>
            </div>
          </div>
        </div>
      })}
    </div>
  )
}

const ClassifiedSentences3 = ({ bucketsDic = {}, coreSentencesScore = {} }) => {
  return (
    <div style={{ width: '1400px', padding: '20px' }}>
      {buckets.map(bucket => {
        const sents = Object.keys(bucketsDic[bucket] || {});
        return <div>
          {sents.length > 0 ?
            <div style={{ backgroundColor: 'grey', fontSize: '36px', padding: '20px 0px' }}>{bucket}</div> : null}
          <div style={{ display: 'flex' }}>
            {/* <div style={{ width: '700px' }}>
              <ol>
              {sents.map((sent, index) => <SentenceComp2 bucket={bucket} index={index} sent={sent} tokensArray={oldClassifiedSentences[sent]} allClassifiedTokens={oldAllClassifiedSentences[sent]} />)}
              </ol>
            </div> */}

            <div style={{ width: '1400px' }}>
              <ol>
                {sents.map((sent, index) => {
                  if (bucket === 'formulation/definition') {
                    console.log('sent------------', sent, bucketsDic[bucket][sent], coreSentencesScore[sent])
                  }
                  return (
                    <li key={index}>
                      <div style={{ display: 'flex' }}>

                        <div style={{ width: '100%' }}>
                          <SentenceComp2 bucket={bucket} index={index} sent={sent} tokensArray={bucketsDic[bucket][sent]} scores={coreSentencesScore[sent]} />
                        </div>
                      </div>
                    </li>
                  )

                }
                )}
              </ol>
            </div>
          </div>
        </div>
      })}
    </div>
  )
}
class SummaryBucketSubBucket extends React.Component {
  render() {
    const { bucketsDic = {}, coreSentencesScore = {} } = this.props;
    return (
      <div style={{ width: '1400px', padding: '20px' }}>
        {bucketSubBucket.map(bucket => {
          // const sents = Object.keys(bucketsDic[bucket] || {});
          return <div>
            <div style={{ backgroundColor: 'grey', fontSize: '36px', padding: '20px 0px' }}>{bucket.name}</div>
            {
              bucket.subbuckets.map(subbucket => {

                const sents = Object.keys(bucketsDic[subbucket] || {});

                return <div>
                  {sents.length > 0 ?
                    <div style={{ backgroundColor: 'black', color: 'white', fontSize: '24px', padding: '20px 0px' }}>{subbucket}</div> : null}
                  <div style={{ display: 'flex' }}>
                    {/* <div style={{ width: '700px' }}>
                  <ol>
                  {sents.map((sent, index) => <SentenceComp2 bucket={bucket} index={index} sent={sent} tokensArray={oldClassifiedSentences[sent]} allClassifiedTokens={oldAllClassifiedSentences[sent]} />)}
                  </ol>
                </div> */}

                    <div style={{ width: '1400px' }}>
                      <ol>
                        {sents.map((sent, index) => {

                          return (
                            <li key={index}>
                              <div style={{ display: 'flex' }}>

                                <div style={{ width: '100%' }}>
                                  <SentenceComp2 bucket={subbucket} index={index} sent={sent} tokensArray={bucketsDic[subbucket][sent]} scores={coreSentencesScore[sent]} />
                                </div>
                              </div>
                            </li>
                          )

                        }
                        )}
                      </ol>
                    </div>
                  </div>
                </div>



              })
            }
          </div>
        })}
      </div>
    )
  }
}

const TimeTaken = ({ stats }) => {
  return (
    <div style={{ width: '500px', height: '100px', backgroundColor: 'PaleGreen', padding: '20px' }}>
      <ul>
        <li>Time taken - {stats.time} ms</li>
        <li>Total Sentences - {stats.totalSentences}</li>
        <li>Classified Sentences - {stats.classifiedSentences}</li>
      </ul>
    </div>
  )
}

const SubBucket = ({ index, text, sents }) => {
  const styles = {

    paddingRight: '10px',
    fontSize: '18px'
  }
  return (
    <div>
      <Heading styles={styles} index={index} text={text} />
      {renderSentences(sents)}
    </div>
  );
}

const Heading = ({ index, text, styles }) => {
  return <div style={{ display: 'flex', marginBottom: '20px' }}>
    <div style={styles}>
      {index}
    </div>
    <div style={styles}>
      {text}
    </div>
  </div>
}

const Title = ({ text, fontSize = '22px' }) => {
  return <div style={{ display: 'flex', fontSize, marginBottom: '30px', fontWeight: '500', justifyContent: "center" }}>
    {text}
  </div>
}

const Authors = ({ authors = [] }) => {
  let authorString = ''
  authors.slice(0, -1).forEach(author => authorString += `${author.name}, `)
  authors.slice(-1).forEach(author => authorString += `${author.name}.`)

  return <div style={{ display: 'flex', fontSize: '22px', marginBottom: '30px', justifyContent: "center" }}>
    Authors: {authorString}
  </div>
}

const renderSentences = (sents) => {
  return (
    <ol>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100%' }}>
          {sents.map((sent, index) => {
            let updatedSent = sent;
            if(updatedSent.length > 0) {
              updatedSent = updatedSent[0].toUpperCase() + updatedSent.slice(1,);
            }

            return <li style={{paddingBottom: '10px', fontSize: '18px'}}>{updatedSent}</li>
          })}
        </div>
      </div>
    </ol>
  )
}


class SummaryBucketSubBucket2 extends React.Component {
  render() {
    const { bucketsDic = {} } = this.props;
    return (
      <div style={{ width: '1400px', padding: '20px' }}>
        {bucketSubBucket.map(bucket => {
          return <div>
            <div style={{ backgroundColor: 'grey', fontSize: '36px', padding: '20px 0px' }}>{bucket.name}</div>
            {
              bucket.subbuckets.map(subbucket => {

                const sents = Object.keys(bucketsDic[subbucket] || {});

                return <div>
                  {sents.length > 0 ?
                    <div style={{ backgroundColor: 'black', color: 'white', fontSize: '24px', padding: '20px 0px' }}>{subbucket}</div> : null}
                  <div style={{ display: 'flex' }}>

                    <div style={{ width: '1400px' }}>
                      <ol>
                        {sents.map((sent, index) => {

                          return (
                            <li key={index}>
                              <div style={{ display: 'flex' }}>

                                <div style={{ width: '100%' }}>
                                  {sents.map((sent, index) => <div>{sent}</div>)}
                                </div>
                              </div>
                            </li>
                          )

                        }
                        )}
                      </ol>
                    </div>
                  </div>
                </div>



              })
            }
          </div>
        })}
      </div>
    )
  }
}


function App(props) {
  const { bucketsDic = {}, authors = [], title, objectives } = props;

  console.log('vaibhav--', JSON.stringify(bucketsDic, null, 2), JSON.stringify(objectives, null, 2), JSON.stringify(authors, null, 2), title)
  let Index = 1;

  return (
    <div style={{ padding: '20px' }}>
      <Title text={title} fontSize={'30px'} />
      <Authors authors={authors} />
      {objectives.length > 0 ? <Title text={'Core Obejctives'} fontSize={'22px'} /> : null}
      <ul style={{ justifyContent: 'center', marginBottom: '40px' }}>
        {objectives.map(objective => {
          return (
            <li style={{ marginBottom: '10px' }}>
              {objective}
            </li>
          )
        })}
      </ul>
      <Title text={'Summary'} fontSize={'22px'} />
      {
        bucketSubBucket.map(bucket => {
          const hasSubBucket = bucket.hasSub;
          const IndexString = `${Index}`;
          let subBuckets = [];
          let sents = [];
          if (hasSubBucket) {
            subBuckets = bucket.subbuckets;
          } else {
            sents = Object.keys(bucketsDic[bucket.key] || {});
          }
          Index += 1;
          const styles = {
            marginBottom: '10px',
            paddingRight: '10px',
            fontSize: '22px'
          }
          let subIndex_count = 0;
          if(!hasSubBucket && sents.length === 0) {
            return null;
          }
          return <div style={{marginBottom: '40px'}}>
            <Heading styles={styles} index={IndexString} text={bucket.name} />
            {renderSentences(sents)}
            {subBuckets.map((subBucket, subIndex) => {
              const sents2 = Object.keys(bucketsDic[subBucket.key] || {});
              if(sents2.length === 0) {
                return null;
              }
              subIndex_count += 1;
              const index = `${IndexString}.${subIndex_count}`;
              
              
              return <SubBucket sents={sents2} index={index} text={subBucket.name} />
            })}
          </div>
        })
      }
    </div>
  );
}

export default App;

