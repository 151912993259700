import React, { useState, useEffect, useRef } from 'react';
import { Document } from 'react-pdf'
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import Highlighter from "react-highlight-words";
import selectionAlgorithmConfigFile from './selectionAlgorithmConfigFile.json'
import Summary from './Summary';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';



const COMPARISON_BUCKET = 'Comparative analysis (B) & Adaptation';
const buckets = ['formulation/definition',
  'assumption',
  'possible limitations Or Scope(B)',
  'Method',
  'Comparative analysis (B) & Adaptation',
  'Possible Novelty/Originality',
  'Experiment setup',
  'Research data',
  'Evaluation (how was experiment evaluated)',
  'Result/Observation',
  'Analysis & Explanation of observation',
  'speculation & expectations',
  'Future scope',
  'Special Emphasis'];

const subBucketBucketDic2 = {
  'formulation/definition': 'objective (what is the topic of investigation)',
  'assumption': 'objective (what is the topic of investigation)',
  'possible limitations Or Scope(B)': 'objective (what is the topic of investigation)',
  'Method': 'approach (how the investigation is conducted)',
  'Comparative analysis (B) & Adaptation': 'approach (how the investigation is conducted)',
  'Possible Novelty/Originality': 'approach (how the investigation is conducted)',
  'Experiment setup': 'observation & analysis (what was the outcome of the approach)',
  'Research data': 'observation & analysis (what was the outcome of the approach)',
  'Evaluation (how was experiment evaluated)': 'observation & analysis (what was the outcome of the approach)',
  'Result/Observation': 'observation & analysis (what was the outcome of the approach)',
  'Analysis & Explanation of observation': 'observation & analysis (what was the outcome of the approach)',
  'speculation & expectations': 'speculation & future direction',
  'Future scope': 'speculation & future direction',
  'Special Emphasis': 'speculation & future direction'
}
const bucketSubBucket = [{
  name: 'objective (what is the topic of investigation)',
  subbuckets: [
    'formulation/definition',
    'assumption',
    'possible limitations Or Scope(B)',
  ]
},
{
  name: 'approach (how the investigation is conducted)',
  subbuckets: [
    'Method',
    'Comparative analysis (B) & Adaptation',
    'Possible Novelty/Originality',
  ]
},
{
  name: 'observation & analysis (what was the outcome of the approach)',
  subbuckets: [
    'Experiment setup',
    'Research data',
    'Evaluation (how was experiment evaluated)',
    'Result/Observation',
    'Analysis & Explanation of observation',
  ]
},
{
  name: 'speculation & future direction',
  subbuckets: [
    'speculation & expectations',
    'Future scope',
    'Special Emphasis',
  ]
}]

const subBucketBucketDic = {
  'formulation/definition': 'objective (what is the topic of investigation)',
  'assumption': 'objective (what is the topic of investigation)',
  'possible limitations Or Scope(B)': 'objective (what is the topic of investigation)',
  'Method': 'approach (how the investigation is conducted)',
  'Comparative analysis (B) & Adaptation': 'approach (how the investigation is conducted)',
  'Possible Novelty/Originality': 'approach (how the investigation is conducted)',
  'Experiment setup': 'Experiment setup',
  'Research data': 'Research data',
  'Evaluation (how was experiment evaluated)': 'Evaluation (how was experiment evaluated)',
  'Result/Observation': 'Result/Observation',
  'Analysis & Explanation of observation': 'Analysis & Explanation of observation',
  'speculation & expectations': 'speculation & future direction',
  'Future scope': 'speculation & future direction',
  'Special Emphasis': 'speculation & future direction'
}
const noNegationBuckets = {
  'speculation & future direction': 1
}

const Sentences = ({ sents }) => {
  return (
    <div style={{ width: '500px' }}>
      <ul>
        {sents.map((sent, index) => <li key={index}>{sent}</li>)}
      </ul>
    </div>
  )
}


const SentenceComp = ({ bucket, sent, labels, index, labelsDic, allClassifiedLabels = {} }) => {

  // const citationPresent = sent.split(/((\[[0-9]+, [0-9]+\])?)((\[[0-9]+\])?)/g);
  const citationPresent = sent.split(/\[[0-9]*\]/g);
  const labelValues = labels.map(label => labelsDic[label]);

  if (labelValues.find((item) => item === bucket)) {
    return (<li key={index}>
      <Highlighter
        highlightClassName="YourHighlightClass"
        searchWords={labels}
        autoEscape={true}
        textToHighlight={sent}
      />
      {citationPresent.length > 1 ? <div style={{ backgroundColor: 'Coral' }}> citation present</div> : null}
      {labels.map(label =>
        <div style={{ display: 'flex', padding: '20px 0px' }}>
          <div style={{ backgroundColor: 'AntiqueWhite', paddingRight: "20px" }}>
            {label}
          </div>
          <div style={{ backgroundColor: 'Aquamarine' }}>
            {labelsDic[label]}
          </div>
        </div>
      )}
      {Object.keys(allClassifiedLabels).map(label => {
        if (!labelsDic[label]) {

          return (<div style={{ display: 'flex', backgroundColor: 'MidnightBlue', color: 'white' }}>
            <div style={{ paddingRight: "20px" }}>
              {label}
            </div>
            <div style={{}}>
              {allClassifiedLabels[label]}
            </div>
          </div>);
        }
        return null;
      })}
    </li>);
  }
  return null;
}


const Token = ({ token, bgColor = 'NavajoWhite', fontColor }) => {
  return (<div style={{ display: 'flex', padding: '20px 0px', backgroundColor: bgColor, color: fontColor }}>

    <div style={{ paddingRight: "20px" }}>
      {token.text}
    </div>
    <div style={{ paddingRight: '30px' }}>
      {token[token.found]}
    </div>
    <div style={{ paddingRight: '30px' }}>
      {token.found}
    </div>
    <div style={{ paddingRight: '30px' }}>
      {token.type}
    </div>
    <div style={{}}>
      {token.winnerRule}
    </div>
  </div>)
}

const SentenceComp2 = ({ bucket, sent, index, tokensArray, scores = { scoreStrings: [] } }) => {
  const citationPresent = sent.split(/\[[0-9]*\]/g);
  if (!tokensArray) {
    return null;
  }


  return (
    <div key={index}>
      <div>{sent}</div>

      {/* {citationPresent.length > 1 ? <div style={{ backgroundColor: 'Coral' }}> citation present</div> : null}
      {tokensArray.map(token =>
        <Token token={token} />
      )}

      <div style={{ backgroundColor: 'LightCoral', padding: '20px', fontSize: 20 }}>
        {scores.scoreVal}
      </div>
      <div style={{ backgroundColor: 'LightGray', padding: '20px 0px', fontSize: 20 }}>
        {scores.scoreStrings.map(scoreRow =>
          <div style={{ display: 'flex' }}>
            {scoreRow.map(cell => <div style={{ width: '100%' }}>{cell}</div>)}
          </div>
        )}
      </div> */}

    </div>
  );

}



const ClassifiedSentences = ({ oldresults, classifiedSentences, allClassifiedSentences = {} }) => {
  const sents = Object.keys(classifiedSentences);
  const sents2 = Object.keys(oldresults);
  return (
    <div style={{ width: '1400px', padding: '20px' }}>
      {buckets.map(bucket => {
        return <div>
          <div style={{ backgroundColor: 'grey', fontSize: '36px', padding: '20px 0px' }}>{bucket}</div>
          <div style={{ display: 'flex' }}>
            <div style={{ width: '700px' }}>
              <ol>
                {sents2.map((sent, index) => <SentenceComp bucket={bucket} index={index} sent={sent} labelsDic={oldresults[sent]} labels={Object.keys(oldresults[sent])} allClassifiedLabels={{}} />)}
              </ol>
            </div>

            <div style={{ width: '700px' }}>
              <ol>
                {sents.map((sent, index) => <div>{sent}</div>)}
              </ol>
            </div>
          </div>
        </div>
      })}
    </div>
  )
}


const ClassifiedSentences2 = ({ coreSentencesScore = {}, oldClassifiedSentences, oldAllClassifiedSentences, classifiedSentences, allClassifiedSentences = {} }) => {
  const sents = Object.keys(oldClassifiedSentences);
  return (
    <div style={{ width: '1400px', padding: '20px' }}>
      {buckets.map(bucket => {
        return <div>
          {sents.length > 0 ?
            <div style={{ backgroundColor: 'grey', fontSize: '36px', padding: '20px 0px' }}>{bucket}</div> : null}
          <div style={{ display: 'flex' }}>
            {/* <div style={{ width: '700px' }}>
              <ol>
              {sents.map((sent, index) => <SentenceComp2 bucket={bucket} index={index} sent={sent} tokensArray={oldClassifiedSentences[sent]} allClassifiedTokens={oldAllClassifiedSentences[sent]} />)}
              </ol>
            </div> */}

            <div style={{ width: '1400px' }}>
              <ol>
                {sents.map((sent, index) =>
                  <li key={index}>
                    <div style={{ display: 'flex' }}>
                      {/* <div style={{ width: '100%' }}>
                      <SentenceComp2 bucket={bucket} index={index} sent={sent} tokensArray={oldClassifiedSentences[sent]} allClassifiedTokens={oldAllClassifiedSentences[sent]} />
                      </div> */}
                      <div style={{ width: '100%' }}>
                        <SentenceComp2 bucket={bucket} index={index} sent={sent} tokensArray={classifiedSentences[sent]} allClassifiedTokens={allClassifiedSentences[sent]} scores={coreSentencesScore[sent]} />
                      </div>
                    </div>
                  </li>
                )}
              </ol>
            </div>
          </div>
        </div>
      })}
    </div>
  )
}

const ClassifiedSentences3 = ({ bucketsDic = {}, coreSentencesScore = {} }) => {
  return (
    <div style={{ width: '1400px', padding: '20px' }}>
      {buckets.map(bucket => {
        const sents = Object.keys(bucketsDic[bucket] || {});
        return <div>
          {sents.length > 0 ?
            <div style={{ backgroundColor: 'grey', fontSize: '36px', padding: '20px 0px' }}>{bucket}</div> : null}
          <div style={{ display: 'flex' }}>
            {/* <div style={{ width: '700px' }}>
              <ol>
              {sents.map((sent, index) => <SentenceComp2 bucket={bucket} index={index} sent={sent} tokensArray={oldClassifiedSentences[sent]} allClassifiedTokens={oldAllClassifiedSentences[sent]} />)}
              </ol>
            </div> */}

            <div style={{ width: '1400px' }}>
              <ol>
                {sents.map((sent, index) => {
                  if (bucket === 'formulation/definition') {
                    console.log('sent------------', sent, bucketsDic[bucket][sent], coreSentencesScore[sent])
                  }
                  return (
                    <li key={index}>
                      <div style={{ display: 'flex' }}>

                        <div style={{ width: '100%' }}>
                          <SentenceComp2 bucket={bucket} index={index} sent={sent} tokensArray={bucketsDic[bucket][sent]} scores={coreSentencesScore[sent]} />
                        </div>
                      </div>
                    </li>
                  )

                }
                )}
              </ol>
            </div>
          </div>
        </div>
      })}
    </div>
  )
}
class SummaryBucketSubBucket extends React.Component {
  render() {
    const { bucketsDic = {} } = this.props;
    return (
      <div style={{ width: '1400px', padding: '20px' }}>
        {bucketSubBucket.map(bucket => {
          return <div>
            <div style={{ backgroundColor: 'grey', fontSize: '36px', padding: '20px 0px' }}>{bucket.name}</div>
            {
              bucket.subbuckets.map(subbucket => {

                const sents = Object.keys(bucketsDic[subbucket] || {});

                return <div>
                  {sents.length > 0 ?
                    <div style={{ backgroundColor: 'black', color: 'white', fontSize: '24px', padding: '20px 0px' }}>{subbucket}</div> : null}
                  <div style={{ display: 'flex' }}>

                    <div style={{ width: '1400px' }}>
                      <ol>
                        {sents.map((sent, index) => {

                          return (
                            <li key={index}>
                              <div style={{ display: 'flex' }}>

                                <div style={{ width: '100%' }}>
                                  {sents.map((sent, index) => <div>{sent}</div>)}
                                </div>
                              </div>
                            </li>
                          )

                        }
                        )}
                      </ol>
                    </div>
                  </div>
                </div>



              })
            }
          </div>
        })}
      </div>
    )
  }
}

const TimeTaken = ({ stats }) => {
  return (
    <div style={{ width: '500px', height: '100px', backgroundColor: 'PaleGreen', padding: '20px' }}>
      <ul>
        <li>Time taken - {stats.time} ms</li>
        <li>Total Sentences - {stats.totalSentences}</li>
        <li>Classified Sentences - {stats.classifiedSentences}</li>
      </ul>
    </div>
  )
}


function App() {
  const [classifiedSentences, setClassifiedSentences] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [coreSentences, setCoreSentences] = useState({});
  const [titleInfos, setTitleInfos] = useState({
    problemTitle: [],
    approachTitle: []
  });
  const [coreSentencesScore, setCoreSentencesScore] = useState({});
  const [bucketsDic, setBucketsDic] = useState({});
  const [allClassifiedSentences, setAllClassifiedSentences] = useState([]);
  const [stats, setStats] = useState({ time: 0, totalSentences: 0, classifiedSentences: 0 });

  useEffect(() => {
    // parseAndClassify();
  }, [])

  const getScoreString2 = (key, value, value2, type) => [key, value, value2, type];


  const customScoreSorter = (val) => {

    return (a, b) => {
      return -(a[1].scoreVal - b[1].scoreVal);
    }
  };

  const getScoreForToken = ({ global_cue_frequency, token, positive = true, scoreVal, scoreStrings }) => {
    let positiveNegativeMultiplier = positive ? 1 : selectionAlgorithmConfigFile.negative_voting;
    if (token.type === 'strong') {
      if ((token.found === 't1' || token.found === 't1r')) {
        const cueType = 't1Strong';
        positiveNegativeMultiplier = positive ? 1 : selectionAlgorithmConfigFile.negative_precedence[cueType];
        let score = selectionAlgorithmConfigFile.precedence[cueType] * positiveNegativeMultiplier;
        score = score * global_cue_frequency[token.text];
        scoreVal += score;
        scoreStrings.push(getScoreString2(token.text, 'cue', score, cueType));
      } else if ((token.found === 'y' || token.found === 'r')) {

        const cueType = 't2Strong';
        positiveNegativeMultiplier = positive ? 1 : selectionAlgorithmConfigFile.negative_precedence[cueType];
        let score = selectionAlgorithmConfigFile.precedence[cueType] * positiveNegativeMultiplier;
        score = score * global_cue_frequency[token.text];
        scoreVal += score;
        scoreStrings.push(getScoreString2(token.text, 'cue', score, cueType));
      } else {
        const cueType = 't3Strong';
        positiveNegativeMultiplier = positive ? 1 : selectionAlgorithmConfigFile.negative_precedence[cueType];
        let score = selectionAlgorithmConfigFile.precedence[cueType] * positiveNegativeMultiplier;
        score = score * global_cue_frequency[token.text];
        scoreVal += score;
        scoreStrings.push(getScoreString2(token.text, 'cue', score, cueType));
      }
    } else {
      if ((token.found === 't1' || token.found === 't1r')) {
        const cueType = 't1Weak';
        positiveNegativeMultiplier = positive ? 1 : selectionAlgorithmConfigFile.negative_precedence[cueType];
        let score = selectionAlgorithmConfigFile.precedence[cueType] * positiveNegativeMultiplier;
        score = score * global_cue_frequency[token.text];
        scoreVal += score;
        scoreStrings.push(getScoreString2(token.text, 'cue', score, cueType));
      } else {
        const cueType = 't2Weak';
        positiveNegativeMultiplier = positive ? 1 : selectionAlgorithmConfigFile.negative_precedence[cueType];
        let score = selectionAlgorithmConfigFile.precedence[cueType] * positiveNegativeMultiplier;
        score = score * global_cue_frequency[token.text];
        scoreVal += score;
        scoreStrings.push(getScoreString2(token.text, 'cue', score, cueType));
      }
    }
    return [scoreVal]
  }
  const removePunctuations = (s) => {
    const ans = s.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "");
    if (ans !== s) {

    }
    return ans;
  }

  const negativeVotingRule = ({
    titlesBigrams,
    label,
    negativeTitleScore,
    tokens_array_positive,
    chunk,
    sent
  }) => {
    if (titlesBigrams[chunk]) {
      let sectionLabel = '';
      if (tokens_array_positive.length > 0) {
        sectionLabel = tokens_array_positive[0][tokens_array_positive[0].found];
        if (sectionLabel !== label) {
          negativeTitleScore = true;
        }
      }
    }
    return negativeTitleScore;
  }

  const checkIfKeywordInSent = (sent = '', keyword = '') => {
    return (sent.includes(' ' + keyword + ' '))
      || (sent.slice(0, keyword.length + 1) === keyword + ' ')
      || (sent.slice(-keyword.length - 1) === ' ' + keyword)
  }

  const assignScoreToSentences = ({
    classified_sents,
    approachTitlesBigrams,
    problemTitlesBigrams,
    global_cue_frequency,
    core_sents_after_selection_sents: coreSentences,
    noun_chunks_frequency_and_type,
    classified_sents_resolved,
    chunk_vs_actual_chunk
  }) => {
    const sents = Object.keys(coreSentences);
    let coreSentencesArray = [];
    const noun_chunks_score_list = Object.keys(noun_chunks_frequency_and_type);
    const core_sentences_score = {};
    sents.forEach(sent => {

      const sentText = removePunctuations(sent);
      let scoreVal = 0;
      let scoreStrings = [];
      const tokens_array_positive = coreSentences[sent];
      const tokens_array_negative = classified_sents[sent];


      const chunks_present = [];
      let chunkOtherThanTitle = false;
      let l1L2Count = 0;

      let doNotUseFrequencyRule = false;
      const futureSent = tokens_array_positive.find(token => noNegationBuckets[subBucketBucketDic[token[token.found]]]);
      if (futureSent) {
        doNotUseFrequencyRule = true;
      }


      noun_chunks_score_list.forEach(chunk => {
        const actual_chunk = chunk_vs_actual_chunk[chunk];
        if (checkIfKeywordInSent(sentText, actual_chunk)) {
          const { type } = noun_chunks_frequency_and_type[chunk];
          if (type !== 'title') {
            chunkOtherThanTitle = true;
            l1L2Count += 1;
          }
          chunks_present.push(chunk);
        }
      });

      chunks_present.forEach(chunk => {
        let negativeTitleScore = false;
        const { type } = noun_chunks_frequency_and_type[chunk];
        let score = noun_chunks_frequency_and_type[chunk].score;
        if (doNotUseFrequencyRule) {
          score = selectionAlgorithmConfigFile.precedence[type]
        }
        negativeTitleScore = negativeVotingRule({
          titlesBigrams: approachTitlesBigrams,
          label: 'Method',
          negativeTitleScore,
          tokens_array_positive,
          chunk,
          sent
        });
        negativeTitleScore = negativeVotingRule({
          titlesBigrams: problemTitlesBigrams,
          label: 'formulation/definition',
          negativeTitleScore,
          tokens_array_positive,
          chunk,
          sent
        });
        if (!doNotUseFrequencyRule && negativeTitleScore) {
          score = score * selectionAlgorithmConfigFile.negative_voting;
        }
        scoreVal += score;
        scoreStrings.push(getScoreString2(chunk, noun_chunks_frequency_and_type[chunk].type, score, 'noun_chunks'))
      });



      const cueScored = {};
      tokens_array_positive.forEach(token => cueScored[token.text] = -1);
      let label = '';
      tokens_array_positive.forEach(token => label = subBucketBucketDic[token[token.found]]);
      let noNegation = false;
      if (noNegationBuckets[label]) {
        noNegation = true;
      }

      tokens_array_negative.forEach(token => {
        if (noun_chunks_frequency_and_type[token.text]) {
          return
        }
        if (tokens_array_positive.find(token2 => token2.text === token.text)) {
          if (cueScored[token.text] === -1) {
            [scoreVal] = getScoreForToken({ global_cue_frequency, token, positive: true, scoreVal, scoreStrings });
            cueScored[token.text] = 1;
          }
          return
        }
        const label2 = subBucketBucketDic[token[token.found]];
        let positive = label === label2;
        if (noNegation) {
          positive = true;
        }
        [scoreVal] = getScoreForToken({ global_cue_frequency, token, positive, scoreVal, scoreStrings });
      });
      const score = {
        scoreStrings,
        scoreVal: chunkOtherThanTitle ? scoreVal : 0
      }
      const citationPresent = sent.split(/\[[0-9]*\]/g).length > 1;
      if (checkIfKeywordInSent(sent, 'by') && citationPresent) {
        score.scoreStrings = []
        score.scoreVal = 0;
      }

      core_sentences_score[sent] = score;
      coreSentencesArray.push([sent, score]);
    });




    coreSentencesArray = coreSentencesArray.sort(customScoreSorter());

    const newCoreSentencesScore = {}
    const new_classified_sents = {}
    coreSentencesArray.forEach(obj => {
      newCoreSentencesScore[obj[0]] = obj[1]
      new_classified_sents[obj[0]] = classified_sents_resolved[obj[0]]
    });
    const bucketsDic = {}
    Object.keys(new_classified_sents).forEach(sent => {
      const tokens_array = new_classified_sents[sent];
      tokens_array.forEach(token => {
        const label = token[token.found];
        if (!bucketsDic[label]) {
          bucketsDic[label] = {};
        }
        bucketsDic[label][sent] = new_classified_sents[sent];
      })
    });

    // selection rule 1 start
    buckets.forEach(bucket => {
      const sents = Object.keys(bucketsDic[bucket] || {});
      if (bucket === 'Method') {
        const { upto, criteria } = selectionAlgorithmConfigFile.selectionRules1['Method'];
        if (sents.length > upto) {
          const scoreCriteria = newCoreSentencesScore[sents[upto - 1]].scoreVal * criteria;
          bucketsDic[bucket] = {}
          const newSents = sents.filter(sent => newCoreSentencesScore[sent].scoreVal >= scoreCriteria);
          newSents.forEach(sent2 => {
            bucketsDic[bucket][sent2] = new_classified_sents[sent2];
          })
        }
      } else {
        const { upto, criteria } = selectionAlgorithmConfigFile.selectionRules1['others'];
        if (sents.length > upto) {
          const scoreCriteria = newCoreSentencesScore[sents[upto - 1]].scoreVal * criteria;
          bucketsDic[bucket] = {}
          const newSents = sents.filter(sent => newCoreSentencesScore[sent].scoreVal >= scoreCriteria);
          newSents.forEach(sent2 => {
            bucketsDic[bucket][sent2] = new_classified_sents[sent2];
          })
        }
      }
    });
    // selection rule 1 end


    // order rule   
    const orderedSents = Object.keys(classified_sents);
    buckets.forEach(bucket => {
      const sentsDic = bucketsDic[bucket] || {};
      const newSentsDic = {};
      orderedSents.forEach(sent2 => {

        if (sentsDic[sent2]) {
          newSentsDic[sent2] = sentsDic[sent2];
        }
      });
      bucketsDic[bucket] = newSentsDic;
    });


    setAllClassifiedSentences(classifiedSentences)
    return {
      newCoreSentencesScore,
      bucketsDic,
      new_classified_sents
    }
  }


  const rephraseSents = (sent) => {
    // const sents = Object.keys(classified_sents_newsents);
    // const newDic = {}
    // sents.forEach(sent => {
    //   const originalSent = classified_sents_newsents[sent];
    let updatedSent = sent;
    updatedSent = updatedSent.replace(' we ', " the authors ");
    updatedSent = updatedSent.replace(' we have also ', " the authors have ");
    updatedSent = updatedSent.replace(' our ', " this paper's ");
    if (updatedSent.slice(0, 4) === 'our ') {
      updatedSent = "this paper's " + updatedSent.slice(4);
    }
    if (updatedSent.slice(0, 3) === 'we ') {
      updatedSent = "the authors " + updatedSent.slice(3);
    }
    if (updatedSent.slice(0, 13) === 'we have also ') {
      updatedSent = "the authors have " + updatedSent.slice(3);
    }
    const splits = updatedSent.split('the authors')
    if (splits.length > 2) {
      let updatedString = ''
      splits.forEach((split, index) => {
        if (index === 0) {
          updatedString += split;
        }
        if (index === 1) {
          updatedString += 'the authors' + split;
        }
        if (index >= 1) {
          updatedString += 'they' + split;
        }
      });
      updatedSent = updatedString;
    }
    return updatedSent;
  }


  const parseAndClassify = async () => {
    setShowBucket(false);
    setShowLoader(true);
    const data = new FormData()
    data.append('file', selectedFile);
    const t0 = performance.now();
    const res = await axios.post("http://54.201.133.122:3246/api/getSummary", data, {});
    let classified_sents = {};
    let classified_sents_resolved = {};
    let core_sents_after_selection_sents = {};
    let noun_chunks_frequency_and_type = {};
    let problemTitlesBigrams = {};
    let approachTitlesBigrams = {};
    let global_cue_frequency = {};
    let chunk_vs_actual_chunk = {};
    let allSentencesOrderDic = {}
    let problemTitle = []
    let approachTitle = []
    let authors = []
    let title = []

    if (res && res.data) {
      console.log('vafdsafdsaf', res.data)
      if(res.data.error === true) {
        setShowErrorMessage(true);
        setShowLoader(false)
        return;
      } else {
        [classified_sents, classified_sents_resolved, noun_chunks_frequency_and_type, core_sents_after_selection_sents, problemTitlesBigrams, approachTitlesBigrams, global_cue_frequency, chunk_vs_actual_chunk, allSentencesOrderDic, problemTitle, approachTitle, authors, title] = res.data.data;
      }
    }
    setAuthors(authors)
    setTitle(title)
    var t1 = performance.now();
    console.log('t1-t0', t1 - t0)
    setCoreSentences(core_sents_after_selection_sents);
    const { bucketsDic, newCoreSentencesScore } = assignScoreToSentences({ allSentencesOrderDic, chunk_vs_actual_chunk, global_cue_frequency, problemTitlesBigrams, approachTitlesBigrams, core_sents_after_selection_sents, noun_chunks_frequency_and_type, classified_sents, classified_sents_resolved });


    // setClassifiedSentences(new_classified_sents);

    // console.log('allSentencesOrderDic', JSON.stringify(allSentencesOrderDic, null, 2))
    buckets.forEach(bucket => {
      const sentsDic = bucketsDic[bucket] || {};

      const newDic = {}
      Object.keys(sentsDic).forEach(sent => {
        let updatedSent = sent;
        if (bucket === COMPARISON_BUCKET) {
          const citationPresent = sent.split(/\[ [0-9]+ *\]/g).length > 1;
          const myOrder = allSentencesOrderDic[sent];

          let previousSent = '';
          if (myOrder) {
            const newOrder = myOrder - 1;
            Object.keys(allSentencesOrderDic).forEach(sent2 => {
              if (allSentencesOrderDic[sent2] === newOrder) {
                previousSent = sent2;
                updatedSent = sent2 + ' ' + sent;
              }
            });
          }
          const citationPresent2 = previousSent.split(/\[ [0-9]+ *\]/g).length > 1;
          if (citationPresent || citationPresent2) {
            newDic[updatedSent] = sentsDic[sent];
            newCoreSentencesScore[updatedSent] = newCoreSentencesScore[sent];
            return;
          }
          return;
        }

        if (sent.includes(' they ') || sent.includes(' this ') || sent.includes(' it ') || sent.includes(' above ') || sent.slice(0, 3) === 'it ' || sent.slice(0, 5) === 'this ' || sent.slice(0, 5) === 'they ' || sent.slice(0, 6) === 'above ') {
          const myOrder = allSentencesOrderDic[sent];
          // console.log('my order0', myOrder, sent)
          if (myOrder) {
            const newOrder = myOrder - 1;
            Object.keys(allSentencesOrderDic).forEach(sent2 => {
              if (allSentencesOrderDic[sent2] === newOrder) {
                updatedSent = sent2 + ' ' + sent;
              }
            });
          }
        };
        if (sent.includes(' below ') || sent.slice(0, 6) === 'below ' || sent.includes(' as follows ')) {
          const myOrder = allSentencesOrderDic[sent];
          if (myOrder) {
            let newOrder = myOrder + 1;
            Object.keys(allSentencesOrderDic).forEach(sent2 => {
              if (allSentencesOrderDic[sent2] === newOrder) {
                updatedSent += ' ' + sent2;
                console.log('updated sent-------', updatedSent)
              }
            });
            newOrder = myOrder + 2;
            Object.keys(allSentencesOrderDic).forEach(sent2 => {
              if (allSentencesOrderDic[sent2] === newOrder) {
                updatedSent += ' ' + sent2;
                console.log('updated sent-------', updatedSent)
              }
            });
            newOrder = myOrder + 3;
            Object.keys(allSentencesOrderDic).forEach(sent2 => {
              if (allSentencesOrderDic[sent2] === newOrder) {
                updatedSent += ' ' + sent2;
                console.log('updated sent-------', updatedSent)
              }
            });

          }
        };
        newDic[rephraseSents(updatedSent)] = sentsDic[sent];
        newCoreSentencesScore[rephraseSents(updatedSent)] = newCoreSentencesScore[sent];
      });
      // if (bucket === 'formulation/definition') {
      //   problemTitle.forEach(problem => {
      //     const newSent = 'the core objective of the paper is ' + problem
      //     newDic[newSent] = [];
      //     newCoreSentencesScore[newSent] = {
      //       scoreVal: 100,
      //       scoreStrings: []
      //     };
      //     console.log('vaibhav-----', newSent)
      //   });
      // }
      console.log('newDic-----', newDic)
      bucketsDic[bucket] = newDic;
    });
    console.log('problem title', problemTitle)
    titleInfos.problemTitle = problemTitle.filter(a => {
      const c = a.split(' ').filter(b => b !== '')
      return c.length > 1
    })
    titleInfos.approachTitle = approachTitle.filter(a => {
      const c = a.split(' ').filter(b => b !== '')
      return c.length > 1
    });
    setShowBucket(true);
    setTitleInfos({ ...titleInfos });
    setBucketsDic(bucketsDic);
    setCoreSentencesScore(newCoreSentencesScore);
    buttontRef.current.click()
    // const input = document.getElementById('divIdToPrint');
    // console.log('iput======', input)
    // html2canvas(document.body, {
    //   onrendered: function(canvas) {
    //     var pdf = new jsPDF();
    //     var marginLeft=20;
    //     var marginRight=20
    //     pdf.addImage(canvas.toDataURL("image/jpeg"),"jpeg",marginLeft,marginRight)
    //     // window.location=pdf.output("download.pdf")
    //     // window.location=pdf.output('dataurlnewwindow')
    //     pdf.save("download.pdf");
    //   }
    // });
    // html2canvas(input)
    //   .then((canvas) => {
    //     const imgData = canvas.toDataURL('image/png');
    //     const pdf = new jsPDF();
    //     pdf.addImage(imgData, 'JPEG', 0, 0);
    //     // pdf.output('dataurlnewwindow');
    //     pdf.save("download.pdf");
    //   })
    //   ;


    // setAllClassifiedSentences(classified_sents);
    // setClassifiedSentences(classified_sents_resolved);
    // setAllClassifiedSentences(classified_sents);
setShowLoader(false)
  
  }
  const [selectedFile, setSelectedFile] = useState(null);
  const [showBucket, setShowBucket] = useState(false);
  const [title, setTitle] = useState('');
  const [authors, setAuthors] = useState([]);
  const onUploadFile = (e) => {
    if (e.target && e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0])
    }
  }
  const renderSomething = (v) => {

    return <div>

      <div style={{ marginBottom: '40px' }}>
        title: {v[0][0]}
      </div>

      <div style={{ marginBottom: '20px' }}>
        abstract
          </div>
      <ol>
        {v[1].map(x => <li>{x} </li>)}
      </ol>
      <div style={{ marginBottom: '40px' }} />

      <div style={{ marginBottom: '20px' }}>
        abstract problems
          </div>
      <ol>
        {v[2].map(x => <li>{x} </li>)}
      </ol>
      <div style={{ marginBottom: '40px' }} />

      <div style={{ marginBottom: '20px' }}>
        abstract approaches
          </div>
      <ol>
        {v[3].map(x => <li>{x} </li>)}
      </ol>
      <div style={{ marginBottom: '40px' }} />



      <div style={{ marginBottom: '20px' }}>
        title problems
          </div>
      <ol>
        {v[4].map(x => <li>{x} </li>)}
      </ol>
      <div style={{ marginBottom: '40px' }} />



      <div style={{ marginBottom: '20px' }}>
        title approaches
          </div>
      <ol>
        {v[5].map(x => <li>{x} </li>)}
      </ol>
      <div style={{ marginBottom: '40px' }} />

    </div>
  }
  const componentRef = useRef();
  const buttontRef = useRef();
  return (
    <div>
      <div style={{ display: 'flex' }}>
        <input type="file" name="file" onChange={onUploadFile} />
        <Button onClick={parseAndClassify}>get Summary</Button>
      </div>
      <ReactToPrint
        trigger={() => <button style={{ visible: 'hidden' }} ref={buttontRef}></button>}
        content={() => componentRef.current}
      />
      {showErrorMessage ? <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center' }}>
            <div style={{ fontSize: '24px', flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
              Some error occured, please refresh the page</div></div> : null}
      <div ref={componentRef} style={{ padding: '20px' }}>
        {showBucket ?
          <Summary
            authors={authors}
            title={title}
            bucketsDic={bucketsDic}
            objectives={titleInfos.problemTitle}
          /> : null}
          {showLoader ? <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center' }}>
            <div style={{ flexDirection: 'column', display: 'flex', justifyContent: 'center' }}>
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner></div></div> : null}
      </div>

    </div>

  );
  return (
    <div>

      <div style={{ display: 'flex' }}>
        <input type="file" name="file" onChange={onUploadFile} />
        <button onClick={parseAndClassify}>Parse And Classify</button>
      </div>
      <div id='divIdToPrint'>


        <ReactToPrint
          trigger={() => <button style={{ visible: 'hidden' }} ref={buttontRef}></button>}
          content={() => componentRef.current}
        />
        <div ref={componentRef} style={{ padding: '20px' }}>
          {showBucket ?

            <div>
              <div>
                Title:  {title}
              </div>
              <div>Authors</div>
              <ol>
                {authors.map(author => <li>{author.name}</li>)}
              </ol>

              {titleInfos.problemTitle.length > 0 ?
                <div style={{}}>Problems: </div> : null}
              <ul>
                {titleInfos.problemTitle.slice(0, 1).map(problem => {

                  return (
                    <li style={{}}>
                      {problem}
                    </li>
                  )
                })}
              </ul>
              {titleInfos.approachTitle.length > 0 ?
                <div style={{}}>Approaches: </div> : null}
              <ul>
                {titleInfos.approachTitle.slice(0, 1).map(approach => {
                  return (
                    <li style={{}}>
                      {approach}
                    </li>
                  )
                })}
              </ul>

              <SummaryBucketSubBucket bucketsDic={bucketsDic} coreSentencesScore={coreSentencesScore} />
            </div>
            : null}

        </div>
      </div>


      <ol>
        {titleAnalysis.map(x => {
          const [title, non, prob, appr] = x;
          return <li style={{ paddingBottom: '50px', padding: '20px' }}>
            <div style={{ fontWeight: '500' }}>Title</div>
            <div style={{ paddingBottom: '20px' }}> {title}</div>
            <div style={{ fontWeight: '500' }}> Problems </div>
            <div>
              {Object.keys(prob).map(p => <div>{p}</div>)}
            </div>
            <div style={{ paddingBottom: '20px' }} />

            <div style={{ fontWeight: '500' }}> Approaches </div>
            <div>
              {Object.keys(appr).map(p => <div>{p}</div>)}
            </div>
            <div style={{ paddingBottom: '20px' }} />

            <div style={{ fontWeight: '500' }}> not classified </div>
            <div>
              {non.map(p => <div>{p}</div>)}
            </div>
            <div style={{ paddingBottom: '20px' }} />


          </li>
        }


        )}
      </ol>
    </div>
  );
}

export default App;
const titleAnalysis = []
